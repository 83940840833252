import { onBeforeMount, ref } from "vue";
import { useAuthManager } from "./useAuthManager"
import { navigateTo } from "#app";
import { useApiClient } from "./useApiClient";

export function useAuthGuard() {
    const { authManager } = useAuthManager();
    const { setupApiClient } = useApiClient();
    const isAuthenticating = ref(true);
    onBeforeMount(async () => { 
      const isAuth = await authManager.isAuthenticated();
        if (!isAuth) {
          return navigateTo({ name: 'Auth' });
        }

        try {
          const accessToken = await authManager.accessToken();
          await setupApiClient(accessToken);
    
          setTimeout(()=> {
              isAuthenticating.value = false;
          }, 1200);
        } catch(e: any) {
          console.log(e);
          return navigateTo({ name: 'Auth' })
        }
      });

    return { isAuthenticating }   
}